import React, {useState} from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Form, Input, Button, Checkbox, Card, Divider, Radio, Alert } from 'antd';
import { UserOutlined, LockOutlined,} from '@ant-design/icons';
import { connect } from 'react-redux';
import { login } from '../actions/user';

const errorTimer = () => (new Promise(resolve => setTimeout(resolve, 3000)));
const LoginForm = (props) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    let navigate = useNavigate();
    let location = useLocation();
    let from = location.state?.from?.pathname || "/";

    const onFinish = values => {
        console.log('Success:', values);
        props.login(values);
        // sessionStorage.setItem('token', values.username);
        // sessionStorage.setItem('port', values.password);
        // window.location.href = '/';
        // console.log(from);
        // navigate(from, { replace: true });
    };
    
    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    return (<div style={{display:'flex', alignItems:'center', minHeight:'100vh', justifyContent:'center'}}>
        <Card bordered={false} className="login-form" 
            headStyle={{textAlign:'center', margin:'0 24px', padding:0}}
            bodyStyle={{padding:'12px'}} style={{minWidth: 320}}
            >
            <h1 style={{textAlign:'center'}}>superant</h1>
        <Form onFinish={onFinish} onFinishFailed={onFinishFailed}>
            <Form.Item 
                name="email"
                rules={[{ required: true, message: '아이디를 입력하세요.' }]}
                style={{marginBottom:'10px'}}>
                <Input
                    prefix={<UserOutlined  style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder="아이디"
                    size="large"
                />
            </Form.Item>
            <Form.Item name="passwd" rules={[{ required: true, message: '비밀번호를 입력하세요.' }]}>
                <Input
                    prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} /> }
                    type="password"
                    placeholder="비밀번호"
                    size="large"
                />
            </Form.Item>
            
            {props.message?(<Form.Item ><Alert type="error" message={props.message} banner /></Form.Item>):(null)}

            <Form.Item style={{marginBottom:0}}>
            <Button type="primary" loading={loading} htmlType="submit" className="login-form-button" size="large" block>로그인</Button>
            </Form.Item>
        </Form>
        
        {/* <div style={{marginTop:'10px'}}>
            <Link to="signup">가입하기</Link>
            <Link to="forgot" style={{float:'right'}}>비밀번호 재설정</Link>
        </div> */}
        <Divider />
        <div style={{textAlign:'center', fontSize:'11px', marginBottom:'10px'}}>
            {/* <Link to="policy/terms" style={{color:'#666'}}>이용약관</Link>
            <Divider type="vertical" />
            <Link to="policy/privacy" style={{color:'#666'}}><strong>개인정보처리방침</strong></Link> */}
            {/*<Divider type="vertical" />
            <Link to="help" style={{color:'#666'}}>고객센터</Link>*/}
        </div>
        <p style={{textAlign:'center', fontSize:'11px'}}>&copy; GB Corp.</p>
        </Card>
    </div>);
};

export default connect(state => state.user, { login })(LoginForm)