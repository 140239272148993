import React, { useState } from 'react';
import { PageHeader, Button, Select, Input, Tabs, Card, Switch, Drawer, DatePicker, Table, Typography, Spin, Result } from 'antd';
import NumberFormat from 'react-number-format';
// import { Stocks } from '../components';
import { ReloadOutlined, SettingOutlined } from '@ant-design/icons';
// import { StockTable, IndicesTable } from '../components/Tables';
import moment from 'moment';
import { gql, useQuery } from '@apollo/client';

const { TabPane } = Tabs;
const { Search } = Input;
const { Option } = Select;
const { Text } = Typography;

const STOCK_ITEM_DATA = gql`
  fragment StockItem on Stock {
    code
    name
    market
    shares
    rank {
      per
      pfcr
      pbr
      psr
      gpa
      asset
      bizLoan
      price
      score
      totalRank
    }
    price {
      close
      rate
      totalValue
    }
  }
`;

const GET_STOCKS = gql`
  query GetStockList($after: String) {
    stocks(after: $after) {
      count
      rows {
        ...StockItem
      }
    }
  }
  ${STOCK_ITEM_DATA}
`;

const colStocks = [
    
    {
      title: '종목명',
      dataIndex: 'name',
      fixed: 'left',
      sorter: (a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      },
      sortDirections: ['ascend','descend'],
      // filters: [
      //   {
      //     text: '삼성',
      //     value: '삼성',
      //   },
      //   {
      //     text: 'Category 1',
      //     value: 'Category 1',
      //     children: [
      //       {
      //         text: 'Yellow',
      //         value: 'Yellow',
      //       },
      //       {
      //         text: 'Pink',
      //         value: 'Pink',
      //       },
      //     ],
      //   },
      //   {
      //     text: 'Category 2',
      //     value: 'Category 2',
      //     children: [
      //       {
      //         text: 'Green',
      //         value: 'Green',
      //       },
      //       {
      //         text: 'Black',
      //         value: 'Black',
      //       },
      //     ],
      //   },
      // ],
      // filterMode: 'tree',
      filterSearch: true,
      onFilter: (value, record) => record.name.includes(value),
    },
    {
        title: '종목코드',
        dataIndex: 'code',
        sorter: (a, b) => {
          if (a.code < b.code) {
            return -1;
          }
          if (a.code > b.code) {
            return 1;
          }
          return 0;
        },
        sortDirections: ['ascend','descend'],
        filters: [
          {
            text: '005930',
            value: '삼성',
          },
        ],
        filterSearch: true,
        onFilter: (value, record) => record.name.includes(value),
      },
    {
      title: '현재가',
      align:'right',
      render: (text, record) => {
        let color = '#666';
        if(record.price?.rate>0) color = '#f5222d';
        else if(record.price?.rate<0) color = '#1890ff';
        return <NumberFormat value={record.price?.close} displayType={'text'} thousandSeparator={true} style={{color:color}} />
      },
      sorter: (a, b) => (a.price?.close || 0) - (b.price?.close || 0),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '상장주식수',
      dataIndex: 'shares',
      align:'right',
      render: (text, record) => {
        let color = '#666';
        return <NumberFormat value={text} displayType={'text'} thousandSeparator={true} style={{color:color}} />
      },
      sorter: (a, b) => a.shares - b.shares,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '시가총액',
      dataIndex: 'rate',
      align:'right',
      sorter: (a, b) => (a.price?.totalValue || 0) - (b.price?.totalValue || 0),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => {
        let color = '#666';
        const total = (record.price?.totalValue || 0) / 100000000; //record.price?.close * record.shares / 100000000;
        return <NumberFormat value={Math.round(total)} displayType={'text'} thousandSeparator={true} style={{color:color}} />
      },
    },
    {
      title: '1/PER',
      align:'right',
      sorter: {
        compare: (a, b) => a.rank.per - b.rank.per,
        multiple: 5,
      },
      render: (text, record) => {
        // let symbol = '(0)';
        // const yesterday = record.close-record.change;
        // const rate = ((text-yesterday)/yesterday*100).toFixed(2);
        // if(yesterday>text) symbol = (<span style={{color:'#1890ff'}}>({rate}%)</span>);
        // else if(yesterday<text) symbol = (<span style={{color:'#f5222d'}}>({rate}%)</span>);
        return <NumberFormat value={record.rank.per} displayType={'text'} thousandSeparator={true} decimalScale={2} />;
      }
    },
    {
      title: '1/PFCR',
      dataIndex: 'low',
      align:'right',
      sorter: {
        compare: (a, b) => a.rank.pfcr - b.rank.pfcr,
        multiple: 4,
      },
      render: (text, record) => {
        // let symbol = '(0)';
        // const yesterday = record.close-record.change;
        // const rate = ((text-yesterday)/yesterday*100).toFixed(2);
        // if(yesterday>text) symbol = (<span style={{color:'#1890ff'}}>({rate}%)</span>);
        // else if(yesterday<text) symbol = (<span style={{color:'#f5222d'}}>({rate}%)</span>);
        return <NumberFormat value={record.rank.pfcr} displayType={'text'} thousandSeparator={true} decimalScale={2} />;
      }
    },
    {
      title: '1/PBR',
      dataIndex: 'high',
      align:'right',
      sorter: {
        compare: (a, b) => a.rank.pbr - b.rank.pbr,
        multiple: 3,
      },
      render: (text, record) => {
        return <NumberFormat value={record.rank.pbr} displayType={'text'} thousandSeparator={true} decimalScale={2} />;
      }
    },
    {
      title: '1/PSR',
      dataIndex: 'volume',
      align:'right',
      sorter: {
        compare: (a, b) => a.rank.psr - b.rank.psr,
        multiple: 2,
      },
      render: (text, record) => {
        return <NumberFormat value={record.rank.psr} displayType={'text'} thousandSeparator={true} decimalScale={2} />;
      }
    },
    {
        title: 'GP/A',
        align:'right',
        sorter: {
          compare: (a, b) => a.rank.gpa - b.rank.gpa,
          multiple: 1,
        },
        render: (text, record) => {
          return <NumberFormat value={record.rank.gpa} displayType={'text'} thousandSeparator={true} decimalScale={2} />;
        }
      },
      {
        title: '자산성장률',
        dataIndex: 'volume',
        align:'right',
        render: (text, record) => {
          return <NumberFormat value={record.rank.asset} displayType={'text'} thousandSeparator={true} decimalScale={2} />;
        }
      },
      {
        title: '영업이익',
        dataIndex: 'volume',
        align:'right',
        render: (text, record) => {
          return <NumberFormat value={record.rank.bizLoan} displayType={'text'} thousandSeparator={true} decimalScale={2}  />;
        }
      },
      {
        title: '주가변동성',
        align:'right',
        render: (text, record) => {
          return <NumberFormat value={record.rank.price} displayType={'text'} thousandSeparator={true} decimalScale={2} />;
        }
      },
      {
        title: '점수',
        align:'right',
        sorter: (a, b) => a.rank.score - b.rank.score,
        sortDirections: ['descend', 'ascend'],
        render: (text,record) => {
          return <NumberFormat value={record.rank.score} displayType={'text'} thousandSeparator={true} />;
        }
      },
      {
        title: '순위',
        align:'right',
        sorter: (a, b) => a.rank.totalRank - b.rank.totalRank,
        sortDirections: ['descend', 'ascend'],
        render: (text,record) => {
          return <NumberFormat value={record.rank.totalRank} displayType={'text'} thousandSeparator={true} />;
        }
      },
];

const StockTable = ({loading, data, action}) => {
  const [pageSize, setPageSize] = useState(20);
    return (
      <Table loading={loading} columns={colStocks} dataSource={data} size="small" scroll={{ x: 1200 }}
        onRow={(record, rowIndex) => {
          return {onClick:action.bind(null, record)}
        }}
        // summary={pageData => {
        //   let totalCount = 0;
        //   let totalClose = 0;
        //   let totalChange = 0;
        //   let totalRate = 0;
        //   pageData.forEach(({ close, change, rate }) => {
        //     totalClose += parseInt(close);
        //     totalChange += parseInt(change);
        //     totalRate += rate;
        //     totalCount++;
        //   });
        //   let profit_rate = totalRate/totalCount;
  
        //   return (
        //     <>
        //       <Table.Summary.Row align="right">
        //         <Table.Summary.Cell colSpan={2}><Text>합계</Text></Table.Summary.Cell>
        //         <Table.Summary.Cell><Text type="danger" align="right"><NumberFormat value={totalClose} displayType={'text'} thousandSeparator={true} /></Text></Table.Summary.Cell>
        //         <Table.Summary.Cell><Text type="danger"><NumberFormat value={totalChange} displayType={'text'} thousandSeparator={true} /></Text></Table.Summary.Cell>
        //         <Table.Summary.Cell><Text type="danger"><NumberFormat value={profit_rate.toFixed(2)} displayType={'text'} suffix="%" thousandSeparator={true} /></Text></Table.Summary.Cell>
        //         <Table.Summary.Cell colSpan={4}></Table.Summary.Cell>
        //       </Table.Summary.Row>
        //     </>
        //   );
        // }}
        pagination={{pageSize, onChange: (page, size)=>setPageSize(size)}}
        />
      )
};

export default function Ranking() {
  const { data, loading, error, refetch, fetchMore } = useQuery(GET_STOCKS);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  if (loading) return <Spin />;
  if (error) return <Result status="500" title="500" subTitle={error.message} extra={<Button type="primary">다시시도</Button>} />;
  // console.log(data);
  // const { stocks } = data;
    return (
    <>
        <PageHeader
            title="퀀트투자"
            subTitle="종목순위"
            // extra={[
            //     <Switch key="1" checkedChildren="실시간" unCheckedChildren="꺼짐" onChange={this.realtime} />,
            //     <DatePicker key="2" defaultValue={moment()} format={'YYYY-MM-DD'} onChange={this.changeDate} />,
            //     <Button key="3" onClick={this.setting}><SettingOutlined /></Button>]}
            footer={
                <Tabs defaultActiveKey={'kospi'} onChange={()=>null}>
                <TabPane tab="종합순위" key="rank" />
                <TabPane tab="신한스코어" key="shinhan" />
                </Tabs>
            }
            style={{backgroundColor:"#fff", margin:"-24px -16px 16px"}}
            >
        </PageHeader>
        
        <Card 
            title={[
            <Select placeholder="업종선택" key="1" defaultValue="all" onChange={()=>null} style={{width:'150px'}}>
                <Option value="all">전체</Option>
                <Option value="kospi">코스피</Option>
                <Option value="kosdaq">코스닥</Option>
            </Select>]}
            extra={[
                <Button key="4" onClick={()=>refetch()}><ReloadOutlined /></Button>
            ]}
            >
            <StockTable data={data?.stocks?.rows} loading={loading} action={()=>null} />
        </Card>
        {/* <StockDrawer stock={''} visible={this.state.drawer} onClose={this.onClose} /> */}
    </>
    );
}
