import React from 'react';
import { BrowserRouter, Routes, Route, Link, useNavigate, useLocation, Navigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useQuery, gql } from "@apollo/client";
import { routes } from './navigations';
import Login from './views/Login';
import DefaultLayout from './containers/Default';
import { Indicator } from './components/Indicators';
import './App.less';

// function RequireAuth({ children }) {
//   let auth = useAuth();
//   let location = useLocation();

//   if (!auth.user) {
//     // Redirect them to the /login page, but save the current location they were
//     // trying to go to when they were redirected. This allows us to send them
//     // along to that page after they login, which is a nicer user experience
//     // than dropping them off on the home page.
//     return <Navigate to="/login" state={{ from: location }} replace />;
//   }

//   return children;
// }

const IS_LOGGED_IN = gql`
  query IsUserLoggedIn {
    isLoggedIn @client
  }
`;

function IsLoggedIn() {
  const { data } = useQuery(IS_LOGGED_IN);
  return data.isLoggedIn ? <DefaultLayout /> : <Login />;
}

function RequireAuth({ children }) {
  const user = useSelector(state => state.user);
  console.log('RequireAuth:', user);
  let location = useLocation();
  if(user.loading) return <Indicator />;
  if (!user.auth) return <Navigate to="/login" state={{ from: location }} replace />;
  return children
}

function App() {
  // const user = useSelector(state => state.user);
  // console.log('App:',user);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route element={<RequireAuth><DefaultLayout /></RequireAuth>}>
          {routes.map((route, idx) => <Route key={idx} path={route.path} element={<route.component />} />)}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

let AuthContext = React.createContext();
function useAuth() {
  return React.useContext(AuthContext);
}



function AuthStatus() {
  let auth = useAuth();
  let navigate = useNavigate();

  if (!auth.user) {
    return <p>You are not logged in.</p>;
  }

  return (
    <p>
      Welcome {auth.user}!{" "}
      <button
        onClick={() => {
          auth.signout(() => navigate("/"));
        }}
      >
        Sign out
      </button>
    </p>
  );
}

function AuthProvider({ children }) {
  let [user, setUser] = React.useState('anonymous');

  let signin = (newUser, callback) => {
    return () => null;
    // return fakeAuthProvider.signin(() => {
    //   setUser(newUser);
    //   callback();
    // });
  };

  let signout = (callback) => {
    return () => null;
    // return fakeAuthProvider.signout(() => {
    //   setUser(null);
    //   callback();
    // });
  };

  let value = { user, signin, signout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default App;
