import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import user from './user';
import { checkAuth } from '../actions/user';

const middleware = [ thunk ];
const rootReducer = combineReducers({user});
// export const store = createStore(rootReducer,  applyMiddleware(...middleware));

export const configureStore = () => {
    const store = createStore(rootReducer,  applyMiddleware(...middleware));
    store.dispatch(checkAuth());

    return store;
};