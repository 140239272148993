import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged, signInWithCustomToken, signInWithEmailAndPassword, signOut } from "firebase/auth";
// import { getDatabase, ref, onChildAdded, onChildChanged, onChildRemoved } from "firebase/database";

const legacy = {
    apiKey: "AIzaSyDhCLo8DQqlsDXwfLSukstMEhdmGZYCyPg",
    authDomain: "coconut-waiting-biz.firebaseapp.com",
    databaseURL: "https://coconut-waiting-biz.firebaseio.com",
    projectId: "coconut-waiting-biz",
    storageBucket: "coconut-waiting-biz.appspot.com",
    messagingSenderId: "886312626353"
};

const superant = {
    apiKey: "AIzaSyDKZtFIS32mPCYKIlRGnhs4f1YFxDvpQeg",
    authDomain: "superant-trader.firebaseapp.com",
    databaseURL: "https://superant-trader.firebaseio.com",
    projectId: "superant-trader",
    storageBucket: "superant-trader.appspot.com",
    messagingSenderId: "949801056174",
    appId: "1:949801056174:web:4f5c244995190cbf36fa3f"
};

// const config_kr = {
//     apiKey: "AIzaSyBtGhID0WAxUzNAG5Oz1-fPdVIkOxoALmE",
//     authDomain: "coconut-kr.firebaseapp.com",
//     databaseURL: "https://coconut-kr.firebaseio.com",
//     projectId: "coconut-kr",
//     storageBucket: "coconut-kr.appspot.com",
//     messagingSenderId: "170553979453",
//     appId: "1:170553979453:web:419e7d2df42d77fcaf7054",
//     measurementId: "G-8SN4Y29T1L"
// };

class Firebase {
    constructor() {
        const app = initializeApp(superant);
        this.auth = getAuth(app);

        // const appKR = initializeApp(config_kr);
        // this.db = getDatabase(appKR);
    }

    checkAuth = (user) => onAuthStateChanged(this.auth, user);
    doSignInWithToken = (token) => signInWithCustomToken(this.auth, token);
    doSignInWithEmailAndPassword = (email, password) => signInWithEmailAndPassword(this.auth, email, password);
    doSignOut = () => signOut(this.auth);
    // getWaitings = (placeKey, group) => ref(this.db, `waitings/${placeKey}/${group}`);
    // getWaitingsCount = (placeKey) => ref(this.db, `waitings/${placeKey}`);
    // getOrders = (placeKey, floor) => ref(this.db, `orders/${placeKey}/${floor}`);
    // getRewards = (placeKey, device) => ref(this.db, `rewards/${placeKey}/${device}`);
}

export const firebase = new Firebase();
// export { onChildAdded, onChildChanged, onChildRemoved };