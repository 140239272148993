
import {  QuantRanking } from './views';
import {
  AppstoreOutlined,
  BankOutlined,
  FundOutlined,
  ThunderboltOutlined,
  StarOutlined,
  SettingOutlined,
  ExperimentOutlined,
  AuditOutlined,
  BuildOutlined,
  FolderViewOutlined,
  RobotOutlined,
  FireOutlined
} from '@ant-design/icons';

export const routes = [
  { path: '/', exact: true, name: '홈', component: QuantRanking },
  { path: '/quant', exact: true, name: '퀀트투자', component: QuantRanking },
  // { path: '/dashboard', exact: true, name: '대시보드', component: Dashboard },
  // { path: '/accounts', exact: true, name: '프로필', component: Accounts },
  // { path: '/stocks', exact: true, name: '전체종목', component: Stocks },
  // { path: '/favorites', exact: true, name: '관심종목', component: Favorites },
  // { path: '/trading', exact: true, name: '트레이딩', component: Trading },
  // { path: '/testing', exact: true, name: '백테스팅', component: Testing },
  // { path: '/settings', exact: true, name: '거래설정', component: Settings },
  // { path: '/announcements', exact: true, name: '공시', component: Announcements },
  // { path: '/news', exact: true, name: '뉴스', component: News },
  // { path: '/dealers', exact: true, name: '거래원', component: Dealers },
  // { path: '/training', exact: true, name: '학습', component: Training },
  // { path: '/portfolios', exact: true, name: '포트폴리오', component: Portfolios },
];
export default {
  items: [
    {
      name: '퀀트투자',
      url: '/quant',
      icon: FundOutlined,
      
    },
  ],
};

// export default {
//     items: [
//       {
//         key: 'dashboard',
//         name: '대시보드',
//         url: '/dashboard',
//         icon: AppstoreOutlined,
//       },
//       {
//         name: '계좌현황',
//         url: '/accounts',
//         icon: BankOutlined,
//       },
//       {
//         name: '종목검색',
//         url: '/stocks',
//         icon: FundOutlined,
//       },
//       {
//         name: '관심종목',
//         url: '/favorites',
//         icon: StarOutlined,
//       },
//       {
//         name: '포트폴리오',
//         url: '/portfolios',
//         icon: FireOutlined,
//       },
//       {
//         name: '자동매매',
//         url: '/trading',
//         icon: ThunderboltOutlined,
//       },
//       {
//         name: '검증',
//         url: '/testing',
//         icon: ExperimentOutlined,
//       },
//       {
//         name: '거래원',
//         url: '/dealers',
//         icon: BuildOutlined,
//       },
//       {
//         name: '공시',
//         url: '/announcements',
//         icon: AuditOutlined,
//       },
//       {
//         name: '뉴스',
//         url: '/news',
//         icon: FolderViewOutlined,
//       },
//       {
//         name: '강화학습',
//         url: '/training',
//         icon: RobotOutlined,
//       },
//       {
//         name: '환경설정',
//         url: '/settings',
//         icon: SettingOutlined,
//       },
//     ],
//   };
  