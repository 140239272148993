import { userActions } from '../actions/actionTypes';

const initState = {
    loading: true,
    data: null,
    auth: false,
    token: null,
};

const userReducer = (state = initState, action)=>{
    switch (action.type) {
        case userActions.AUTH:
        case userActions.SUCCESS:
            return { ...state, data: action.data, auth: action.auth, loading:false };
        case userActions.FAILURE:
            return { ...state, message: action.error.message };
        case userActions.LOGOUT:
            return { ...state, auth: action.auth, loading: false };
        default:
            return state;
    }
};

export default userReducer;