
import { userActions } from "./actionTypes";
import { firebase } from "../utils/firebase";
// import { UserApi } from '../utils/apis';

export const checkAuth = () => (dispatch) => {
    // dispatch({ type: actionTypes.AUTHENTICATION_INIT_STARTED })
    firebase.checkAuth(user => {
        if (user) {
            console.log('checkAuth', user.uid, user.displayName);
            dispatch({ type: userActions.AUTH, data: user, auth: true });
        } else {
            dispatch({type: userActions.LOGOUT, data: null, auth: false});
        }
    });
    // dispatch({ type: actionTypes.AUTHENTICATION_INIT_FINISHED })
};

export const login = (credentials) => (dispatch, getState) => {
    firebase.doSignInWithEmailAndPassword(credentials.email+'@superant.kr', credentials.passwd).then(user => {
        // console.log(user);
        dispatch({type: userActions.SUCCESS, data: user, auth: true});
        window.location.href = '/';
    }).catch(error => {
        dispatch({ type: userActions.FAILURE, error });
    });
};
export const logout = () => (dispatch) => {
    firebase.doSignOut().then(() => {
        dispatch({type: userActions.LOGOUT, data: null, auth: false});
    });
};

//https://tighten.co/blog/react-101-part-4-firebase/#part-2