import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Layout, Menu, Avatar, Dropdown, Badge, Space, Drawer, List, Empty, Input, 
    AutoComplete, Button, Form, Modal, Select, Checkbox } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { logout } from '../actions/user';
import navigation from '../navigations';

const { Header, Content, Footer, Sider, subMenu } = Layout;
    
const SideMenu = ({openKeys, onOpenChange}) => {
    const location = useLocation();
    return (
    <>
    <div className="logo" style={{textAlign:'center', padding:10}}><Avatar style={{backgroundColor:'#fff', color:'#666'}}>S</Avatar></div>
    <Menu defaultSelectedKeys={[location.pathname]} mode="inline" onClick={()=>null} theme="dark"
        openKeys={openKeys}
        onOpenChange={onOpenChange}>
        {
            navigation.items.map((menu, idx) => {
                return (
                    menu.children?
                    <Menu.SubMenu key={menu.url} title={<><menu.icon /><span>{menu.name}</span></>}>
                        {subMenu(menu.children, idx)}
                    </Menu.SubMenu>:
                    <Menu.Item key={menu.url}><menu.icon /><span className="nav-text">{menu.name}</span></Menu.Item>
                    );
            })
        }
    </Menu>
    </>
    );
}

function DefaultLayout() {
    const dispatch = useDispatch();
    return (
        <Layout style={{ minHeight: '100vh', minWidth: '320px' }}>
            {/* <Drawer
                    placement="left"
                    closable={false}
                    onClose={this.onClose}
                    visible={this.state.visible}
                    bodyStyle={{padding:0, backgroundColor: '#001529', height:'100%'}}
                    className="hide-drawer"
                >
                <SideMenu history={this.props.history} goPage={this.goPage} />
            </Drawer>*/}
            {/* <Sider
                collapsible collapsed={false} onCollapse={()=>null}
                className="hide-sider"
            >
              <SideMenu />
            </Sider>  */}
            <Layout className="site-layout" >
              <Header className="site-layout-background" style={{ display:'flex', justifyContent:'space-between', alignItems:'center', position: 'sticky', top:0, zIndex: 9, width:'100%', background: '#fff', padding: '0 20px', borderBottom:'1px solid #d8dbe0'}} >
                <Input.Search size="large" placeholder="종목검색" onSearch={()=>null} style={{ width: 200 }} /> {/* enterButton */}
                <div>
                    <Avatar icon={<UserOutlined />} onClick={()=>dispatch(logout())} />
                        {/* <Space>
                        <span onClick={this.showNotifications}><Badge dot={true} offset={[-5,5]}><Avatar style={{backgroundColor:'#fff', color:'#666'}} icon={<BellOutlined />} /></Badge></span>
                        <Dropdown overlay={this.getLoginMenu} trigger={['click']} placement="bottomRight">
                            <Avatar icon={<UserOutlined />} />
                        </Dropdown>
                        </Space> */}
                </div>
              </Header>
              <Content style={{ margin: '16px 16px 0', overflow: 'initial' }}>
                  <Outlet />
                {/* <Suspense fallback={<span>로드중..</span>}>
                    <Switch>
                    {routes.map((route, idx) => {
                        return route.component ? (
                        <Route
                            key={idx}
                            path={route.path}
                            exact={route.exact}
                            name={route.name}
                            render={props => (
                            <route.component {...props} />
                            )} />
                        ) : (null);
                    })}
                    <Redirect from="/" to="/dashboard" />
                    </Switch>
                </Suspense> */}
              </Content>
            </Layout>
        </Layout>
    );
}

export default DefaultLayout;